import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const setData = createAsyncThunk('appGlobleVariable/setData', async params => {
  return {
    selectedSiteID: params.selectedSiteID,
    selectedSiteTimezone: params.selectedSiteTimezone
  }
})


export const appInvoiceSlice = createSlice({
  name: 'appGlobleVariable',
  initialState: {
    selectedSiteID: '',
    selectedSiteTimezone: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setData.fulfilled, (state, action) => {
      state.selectedSiteID = action.payload.selectedSiteID
      state.selectedSiteTimezone = action.payload.selectedSiteTimezone
    })
  }
})

export default appInvoiceSlice.reducer