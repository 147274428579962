// ** Axios
import axios from "axios"
import { toast } from 'react-toastify'
import SweetAlertReactContent from 'sweetalert2-react-content'
import SweetAlertRoot from 'sweetalert2'

const sweetAlert = SweetAlertReactContent(SweetAlertRoot)

// ** Config
import endPointConfig from "./_endPoint"
import { noSearchParams } from "../utility/constant"

const {
	defaultListingParameter,
	methodType,
	backendEndpoint,
	storageTokenKeyName,
	contentTypeEnum,
	orgTokenName,
	isAdminToken
} = endPointConfig

export const axiosCallAdvanced = async ({
	path,
	baseURL,
	method = methodType.get,
	body = {},
	search = defaultListingParameter.search,
	sort = defaultListingParameter.sort,
	sortColumn = defaultListingParameter.sortColumn,
	page = defaultListingParameter.page,
	limit = defaultListingParameter.limit,
	role,
	siteID,
	departmentID,
	country,
	organizationID,
	contentType = contentTypeEnum.applicationJSON,
	parameter = {},
	type
}) => {
	try {
		const params = structuredClone(parameter)
		if (
			method &&
			method === methodType.get &&
			path.split("list").length > 1  &&
			!noSearchParams.includes(path.split('?')[0])
		) {
			const sorting = sort === defaultListingParameter.sort ? sortColumn : `-${sortColumn}`

			params.search = search
			params.sort = sorting
			params.page = page
			params.limit = limit
		}
		if (role && role.length > 0) {
			params.role = role
		}
		if (siteID && siteID.length > 0) {
			params.siteID = siteID
		}
		if (departmentID && departmentID.length > 0) {
			params.departmentID = departmentID
		}
		if (country) {
			params.country = country
		}

		if (type) {
			params.type = type
		  }
		if (organizationID) {
			params.organizationID = type
		}
		const isAdmin = localStorage.getItem(
			isAdminToken
		)
		if (isAdmin) {
			const orgID = localStorage.getItem(
				orgTokenName
			)
			params.orgId = orgID
		}

		const url = backendEndpoint + baseURL + path

		const storedToken = localStorage.getItem(
			storageTokenKeyName
		)
		const response = await axios({
			url,
			params,
			method,
			data: body,
			headers: {
				'Content-Type': contentType,
				Authorization: storedToken
			}
		})
		if (response.data && response.data.message
			&& response.data.message.length > 0) {
			toast.success(response.data.message)
		}

		return response
	} catch (error) {
		console.log("error", error)
		sweetAlert.fire({
			icon: 'error',
			title: error.response.data.message || error.error.message,
			customClass: {
				confirmButton: 'btn btn-primary'
			},
			showClass: {
				popup: 'animate__animated animate__bounceIn'
			},
			buttonsStyling: false
		})

		if (error.response && error.response.status === 401) {
			// window.location.href = '/login'
		}
	}
}

export default {
	axiosCallAdvanced
}
