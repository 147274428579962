// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


// ** Actions


import {OrganizationAPIs, AuthAPIs} from '../apis'
const defaultParams = {
    q: "",
    page: 1,
    isParamChanged: true
}

export const getData = createAsyncThunk('appOrganization/getData', async params => {
    const response = await OrganizationAPIs.List({
        ...params,
		search: params.q
    })

    return {
		params,
		data: response.data.list,
		allData: response.data.list,
		totalPages: response.data.records
	}

})

export const addOrganization = createAsyncThunk('appOrganization/add', async (orgnizationData, { dispatch }) => {
	await OrganizationAPIs.createNewOne({ body: orgnizationData })
	await dispatch(getData(defaultParams))
})

export const editOrganization = createAsyncThunk('appOrganization/edit', async (orgnizationData, { dispatch }) => {
	await OrganizationAPIs.updateOne({ body: orgnizationData })
	await dispatch(removeData())
})

export const getOneData = createAsyncThunk('appOrganization/getOneData', async ({dispatch}) => {
	await   OrganizationAPIs.findOne({  })
	await dispatch(getData(defaultParams))
	return {
        departmentDetail: response.data
    }
})
export const hadnleLogin = createAsyncThunk('appOrganization/login',  (payload) => {

	localStorage.setItem('userData', JSON.stringify(action.payload))
	localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))

	return {
		userData: payload,
		[config.storageTokenKeyName]: [config.storageTokenKeyName]
	}
})

export const handleLogout = createAsyncThunk('appOrganization/logout', () => {
	localStorage.removeItem('userData')
	localStorage.removeItem(config.storageTokenKeyName)
		localStorage.clear()
		state.userData = {}

	return {
		[config.storageTokenKeyName]:null	
	}

})

export const forgotPassword = createAsyncThunk('forgotPassword', async (data, {}) => {
	const result = await AuthAPIs.forgotPassword({ body: data })
	return result
})

export const resetPassword = createAsyncThunk('resetPassword', async (data, {}) => {
	const result = await AuthAPIs.resetPassword({ body: data })
	return result
})

export const getAdminUserData = createAsyncThunk('resetPassword', async () => {
	const result = await AuthAPIs.findOneAdminUser()
	localStorage.setItem('userData', JSON.stringify(result.userData))
	return {
        userData: result.userData
    }
})

const initialUser = () => {
	const item = window.localStorage.getItem('userData')
	//** Parse stored json or if none return initialValue
	return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
	name: 'authentication',
	initialState: {
	  userData: initialUser()
	}
})

export default authSlice.reducer
