// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { SiteAPIs, EndpointConfig, LocationAPIs} from '../../../../apis'


export const siteDaySlotNameEnum = {
	beforeOpen: 'BEFORE_OPEN',
	open: 'OPEN',
	morning: 'MORNING',
	lunch: 'LUNCH',
	afternoon: 'AFTERNOON',
	evening: 'EVENING',
	night: 'NIGHT',
	close: 'CLOSE',
	afterClose: 'AFTER_CLOSE'
}

export const dayNumberFromDaySymbolEnum = { sun: '0', mon: '1', tue: '2', wed: '3', thu: '4', fri: '5', sat: '6' }

const showErrorToast = (error) => {
	return toast.error(
		error.message ||
		error?.response?.data?.message ||
		error.error.message ||
		error.response.message
	)
}

export const getData = createAsyncThunk('appInvoice/getData', async params => {
	try {
		const response = await SiteAPIs.webAppList({
			...params,
			status: true,
			search: params.q,
			page: params.page,
			limit: EndpointConfig.defaultListingParameter.limit
		})

		return {
			params,
			data: response.data.list,
			allData: response.data.list,
			totalPages: response.data.records
		}
	} catch (error) {
		showErrorToast(error)
	}
})

export const getSimpleList = createAsyncThunk('appInvoice/getSimpleList', async params => {
	try {
		const response = await SiteAPIs.simpleList({
			...params,
			search: params.q
		})

		const optionDisplayList = response.data.list.map((siteDetail) => {
			return {
				value: siteDetail._id,
				label: `${siteDetail.name} (${siteDetail.location})`
			}
		})

		return {
			simpleList: response.data.list,
			simpleListCount: response.data.records,
			optionDisplayList
		}
	} catch (error) {
		showErrorToast(error)
	}
})

export const getOneData = createAsyncThunk('appInvoice/getOneData', async params => {
	try {
		const response = await SiteAPIs.findOne(params)

		return {
			oneSiteData: response.data
		}
	} catch (error) {
		showErrorToast(error)
	}
})

export const getlocation = createAsyncThunk('appInvoice/getlocation', async params => {
	try {
		const response = await SiteAPIs.location({
			body: params
		})
		const loactiondata = response.predictions.map((prediction) => {
			return {
				label: prediction.description,
				value: prediction.description
			}
		})
		return {
			loactiondata
		}
	} catch (error) {
		showErrorToast(error)
	}
})

export const getCountry = createAsyncThunk('appInvoice/getCountry', async params => {
	try {
		const response = await SiteAPIs.fetchCountry({
			body: params
		})
		return {
			addressdata : response.result
		}
	} catch (error) {
		showErrorToast(error)
	}
})

export const getTimeZoneData = createAsyncThunk('appInvoice/getTimeZoneData', async params => {
	try {
		const response = await SiteAPIs.fetchTimezoneData({
			body: params
		})
		return {
			timeZoneData : response
		}
	} catch (error) {
		showErrorToast(error)
	}
})

export const clearData = createAsyncThunk('appInvoice/clearData', () => {
	return {
		data: [],
		allData: [],
		totalPages: 0
	}
})

export const setModalData = createAsyncThunk('appInvoice/setModalData', async params => {
	return {
	  modalData: {
		isModalOpen: params.isModalOpen,
		id: params._id
	  }
	}
  })

export const deleteInvoice = createAsyncThunk('appInvoice/deleteInvoice', async (_id, { dispatch, getState }) => {
	try {
		await SiteAPIs.deleteOne({
			body: {
				siteID: _id
			}
		})

		await dispatch(getData({
			...getState().invoice.params,
			isParamChanged: true
		}))
		await dispatch(setModalData({_id : '', isModalOpen: false}))
		return _id
	} catch (error) {
		showErrorToast(error)
	}
})

export const addSite = createAsyncThunk('appInvoice/addInvoice', async (siteData, { dispatch, getState }) => {
	try {
	  const bodyFormData = new FormData()
	  const currentTime = new Date()
  
	  let siteTimingData = JSON.parse(siteData.timing)
	  siteTimingData = siteTimingData.map((obj) => {
		Object.keys(siteDaySlotNameEnum).forEach((slotName) => obj[slotName] && delete obj[slotName]['range'])
		return obj
	  })
	  bodyFormData.append('name', siteData.siteName)
	  bodyFormData.append('location', siteData.location)
	  bodyFormData.append('timing', JSON.stringify(siteTimingData))
	  bodyFormData.append('description', siteData.description)
	//   bodyFormData.append('uploadBlueprint', siteData.uploadBlueprint)
	  bodyFormData.append('timeZoneDifferenceInMinute', currentTime.getTimezoneOffset())
	  if (siteData.timezone) {
		bodyFormData.append('dstOffset', siteData.timezone.dstOffset)
		bodyFormData.append('timeZoneId', siteData.timezone.timeZoneId)
		bodyFormData.append('timeZoneName', siteData.timezone.timeZoneName)
	  }
	  bodyFormData.append('add1', siteData.add1)
	  bodyFormData.append('add2', siteData.add2)
	  bodyFormData.append('city', siteData.city)
	  bodyFormData.append('county', siteData.county)
	  bodyFormData.append('state', siteData.state)
	  bodyFormData.append('country', siteData.country)
	  bodyFormData.append('timezone', siteData.timezone.timeZoneName)
	  bodyFormData.append('google_PlaceID', siteData.googlePlaceID)
	  bodyFormData.append('lat_long', JSON.stringify({ lat: siteData.lat.toString(), log: siteData.log.toString() }))
  
	  await SiteAPIs.createNewOne({
		body: bodyFormData,
		contentType: EndpointConfig.contentTypeEnum.multipartFormData
	  })
  
	  await dispatch(
		getData({
		  ...getState().invoice.params,
		  isParamChanged: true
		})
	  )
	} catch (error) {
	  showErrorToast(error)
	}
  })
  
export const updateSite = createAsyncThunk('appInvoice/updateSite', async (siteData, { dispatch, getState }) => {
	try {
		await SiteAPIs.updateOne({
			body: siteData,
			contentType: EndpointConfig.contentTypeEnum.multipartFormData
		})

		await dispatch(getData({
			...getState().invoice.params,
			isParamChanged: true
		}))

		return {
			oneSiteData: null
		}
	} catch (error) {
		showErrorToast(error)
	}
})


export const timeSlot = createAsyncThunk('appInvoice/getTimeSlot', async params => {
    try {
        const response = await SiteAPIs.timeSlot(params)
        let finalResponse = response.data
		const slotDataForWeek = response.data.siteData.timing
        if (params?.parameter && params?.parameter?.findDate) {
            const openTimeSlot = finalResponse.openTimeSlot
            const timeSlotList = finalResponse.timeSlotList

            let mainData = timeSlotList.filter(timeSlot => {
                return openTimeSlot.some(openSlot => openSlot.label === timeSlot.value)
            })

			mainData = openTimeSlot
			.filter(timeSlot => timeSlot.fromTimeUTC !== null)
			.map(timeSlot => ({
				label: timeSlotList.find(item => item.value === timeSlot.label)?.label || timeSlot.label,
				value: timeSlot.label
			}))
		

            finalResponse = mainData
        } else {
            finalResponse = finalResponse
        }

        return {
            slotData: finalResponse,
			slotDataForWeek
        }
    } catch (error) {
        showErrorToast(error)
    }
})

  export const simpleListCountries = createAsyncThunk('appInvoice/simpleListCountries', async () => {
	const response =  await LocationAPIs.simpleListCountries({

	  })
	  return {
		countriesList: response.data
	  }
  })

  export const webAppList = createAsyncThunk('appInvoice/webAppList', async params => {

	const response =  await LocationAPIs.webAppList({
		parameter: {
			country:params.country
		  }
	  })
	  return {
		mappingData: response.data
	  }
  })


export const getclosingTimeList = createAsyncThunk('appInvoice/getclosingTimeList', async params => {
	try {
		const response = await SiteAPIs.closingTimeList({ 
			body: params 
		})

		return {
			closingTimeData: response.data[0].closingTime,
			siteId: params.siteID
		}
	} catch (error) {
		showErrorToast(error)
	}
})

export const appInvoiceSlice = createSlice({
	name: 'appInvoice',
	initialState: {
		data: [],
		total: 1,
		params: {},
		allData: [],
		slotData: [],
		slotDataForWeek: [],
		modalData: {
			isModelOpen :false,
			id: ''
		  },
		oneSiteData: {
			_id: "",
			name: "",
			description: "",
			location: { country: '', county: '', region: '', city: '' },
			timing: [],
			siteAdminList: []
		},
		simpleList: [],
		countriesList:[],
		simpleListCount: 0,
		optionDisplayList: [],
		mappingData:[],
		loactiondata: {},
		addressdata: {},
		timeZoneData: {},
		closingTimeData: '',
		siteId: ''
	},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getData.fulfilled, (state, action) => {
			if (action.payload.params.isParamChanged) {
				state.data = action.payload.data
			} else {
				state.data = [...state.data, ...action.payload.data]
			}

			state.allData = action.payload.allData
			state.total = action.payload.totalPages
			state.params = action.payload.params
			state.oneSiteData = action.payload.oneSiteData

		})
			.addCase(getOneData.fulfilled, (state, action) => {
				state.oneSiteData = action.payload.oneSiteData
			})
			.addCase(getSimpleList.fulfilled, (state, action) => {
				state.simpleList = action.payload.simpleList
				state.simpleListCount = action.payload.simpleListCount
				state.optionDisplayList = action.payload.optionDisplayList
			})
			.addCase(setModalData.fulfilled, (state, action) => {
				state.modalData = action.payload.modalData
			})
			.addCase(timeSlot.fulfilled, (state, action) => {
				state.slotData = action.payload.slotData.reverse()
				state.slotDataForWeek = action.payload.slotDataForWeek
			})
			.addCase(simpleListCountries.fulfilled, (state, action) => {
				state.countriesList = action.payload.countriesList
			})
			.addCase(webAppList.fulfilled, (state, action) => {
				state.mappingData = action.payload.mappingData
			})
			.addCase(getlocation.fulfilled, (state, action) => {
				state.loactiondata = action.payload.loactiondata
			})
			.addCase(getCountry.fulfilled, (state, action) => {
				state.addressdata = action.payload.addressdata
			})
			.addCase(getTimeZoneData.fulfilled, (state, action) => {
				state.timeZoneData = action.payload.timeZoneData
			})
			.addCase(getclosingTimeList.fulfilled, (state, action) => {
				state.closingTimeData = action.payload.closingTimeData
				state.siteId = action.payload.siteId
			})
	}
})
export default appInvoiceSlice.reducer
